import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getModelUrl, loaded, mapEdgesToNodes, strip } from '../../utils/helpers';
import { Link, useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

const ReactSlick = loadable(() => import('./../common/ReactSlick'));

const ContentPost = ({ data, uid }) => {
  let excerpt;

  if (data?.body?.[0]?.primary?.post_content) {
    let _excerpt = strip(data?.body?.[0]?.primary?.post_content || '');
    let excerptArr = _excerpt.split('.');

    excerpt = excerptArr?.[0] + '.' || '';
    if (excerptArr?.[1]?.trim()) {
      excerpt += ` ${excerptArr?.[1].trim()}.`;

      if (excerptArr?.[2]?.trim()) {
        excerpt += ` ${excerptArr?.[2].trim()}.`;
      }
    }
  } else {
    excerpt = data.post_description.text;
  }

  const cats = data?.category_group
    ?.filter(({ category }) => category?.document && category?.document?.data?.category_name)
    ?.map(({ category }) => category?.document?.data?.category_name);

  return (
    <article className="post">
      <div className="post__image">
        <Link to={getModelUrl('blog', uid)} className="--overlay">
          <GatsbyImage
            image={data?.featured_image?.gatsbyImageData}
            alt={data?.featured_image?.alt || data.post_name}
          />
        </Link>
      </div>
      <div className="post__content">
        <p className="post__category">{cats.length > 0 ? cats.join(', ') : 'Trending'}</p>
        <div className="post__title">
          <Link to={getModelUrl('blog', uid)}>
            <h3>{data.post_name}</h3>
          </Link>
        </div>
        <div className="post__sort-description">
          {excerpt.split(' ').splice(0, 25).join(' ') || ''} ...
        </div>
        <div className="post__read-more">
          <Link to={getModelUrl('blog', uid)}>Read More</Link>
        </div>
      </div>
    </article>
  );
};

const SliderBlogPost = React.memo(({ isHomePage = true }) => {
  const {
    posts: { nodes },
  } = useStaticQuery(query);

  const box = useRef(null);
  const len = nodes.length;

  const settings = {
    slidesToShow: 3,
    infinite: len > 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          infinite: len > 2,
        },
      },
    ],
  };

  function handleSlickGoTo(next = true) {
    if (box.current) {
      next ? box.current.slickNext() : box.current.slickPrev();
    }
  }

  const checkShowArrows = () => {
    if (loaded) {
      const width = window?.innerWidth;
      const len = nodes?.length;
      if (width < 992 && len >= 2) return true;
      else if (len >= 3) return true;
      else return false;
    }
    return false;
  };

  return (
    <div className="main__beauty-blog">
      <div className="container-xl">
        <div className={`main__beauty-blog__title -title --arrows`}>
          {/* <a href="https://www.activeskin.com.au/blog/latest/"> */}
          <h2 className="mb-0">The Active Edit</h2>
          {/* </a> */}

          {checkShowArrows() && (
            <div className="arrows">
              <span className="arrows__btn --left" onClick={() => handleSlickGoTo(false)}></span>
              <span className="arrows__btn --right" onClick={() => handleSlickGoTo()}></span>
            </div>
          )}
        </div>

        <ReactSlick ref={box} {...settings} className="main__beauty-blog__owl-carousel">
          {nodes.map(({ data, uid }, i) => (
            <ContentPost key={i} data={data} uid={uid} />
          ))}
        </ReactSlick>
      </div>
    </div>
  );
});

export default SliderBlogPost;

const query = graphql`
  {
    posts: allPrismicBlogPostNew(
      filter: { data: { is_featured: { eq: true } } }
      sort: { fields: data___published_date, order: DESC }
    ) {
      nodes {
        uid
        url
        data {
          body {
            ... on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
              primary {
                post_content
              }
            }
          }
          category_group {
            category {
              document {
                ... on PrismicBlogCategory {
                  data {
                    category_name
                  }
                }
              }
            }
          }
          featured_image {
            gatsbyImageData(height: 320, breakpoints: 640)
            alt
          }
          post_name
          read_time
          post_description {
            text
          }
        }
      }
    }
  }
`;
